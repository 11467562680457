import React, { useEffect, useState } from "react"
import { Space } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Paragraph from "antd/lib/typography/Paragraph"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import RecordAudioButton from "./RecordAudioButton"
import * as S from "../../styles/PageStyles"
import { VoiceClipType, VoiceProfile } from "./VoicePanel"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import PlayAudioButton from "./PlayAudioButton"
import { getCurrentVoiceClipType } from "./useUpdateVoiceClipType"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import DragToUploadAudio from "./DragToUploadAudio"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

interface Props {
	textId: string | undefined
	description?: string
	voiceClip: VoiceClip | undefined
	voiceClipType: VoiceClipType | undefined
	card: Card
	languageCode: LanguageCode
	voiceProfileDefaults: VoiceProfile[] | undefined
	isAdmin: boolean
}

const AudioFileInput = ({
	textId,
	description,
	voiceClip,
	voiceClipType,
	card,
	languageCode,
	voiceProfileDefaults,
	isAdmin
}: Props) => {
	const [fileUploadMessage, setFileUploadMessage] = useState<string | undefined>(undefined)
	const [fileUploadError, setFileUploadError] = useState<boolean>(false)
	const savedVoiceClipType = getCurrentVoiceClipType(voiceProfileDefaults, voiceClip)

	useEffect(() => {
		setFileUploadMessage(undefined)
		setFileUploadError(false)
	}, [textId])

	return (
		<>
			{description && (
				<Paragraph style={{ marginBottom: 6, marginTop: 4 }} ellipsis>
					{description}
				</Paragraph>
			)}
			<S.FlexBoxStartRow style={{ paddingBottom: 10 }}>
				<Space>
					<>
						{voiceClipType === "custom" && (
							<RecordAudioButton
								textId={textId}
								card={card}
								languageCode={languageCode}
								description={description}
								voiceClip={voiceClip}
							/>
						)}
						{voiceClip && voiceClipType === savedVoiceClipType && (
							<PlayAudioButton color={S.languageColors(languageCode)} fileUrl={voiceClip.fileUrl} />
						)}
					</>
				</Space>
				{fileUploadMessage && (
					<div style={{ flex: 1, paddingLeft: 10, color: fileUploadError ? "var(--mid-red)" : "black" }}>
						{fileUploadMessage}
					</div>
				)}
			</S.FlexBoxStartRow>
			{textId && isAdmin && voiceClipType === "custom" && (
				<DragToUploadAudio
					textId={textId}
					card={card}
					languageCode={languageCode}
					setFileUploadMessage={setFileUploadMessage}
					setFileUploadError={setFileUploadError}
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	isAdmin: selectIsUserAdmin
})

// @ts-ignore
export default connect(mapStateToProps)(AudioFileInput)
