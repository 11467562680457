import React, { useEffect } from "react"
import Phone from "../../assets/images/phone_outline.svg"

export function PreviewFrame({
	type,
	lessonContent,
	questionContent,
	activeOrgData,
	teacherLearnerImgs,
	languageCodes,
	pointer
}) {
	useEffect(() => {
		const iframe = document.querySelector("#previewFrame")
		iframe.addEventListener("load", () => {
			setTimeout(() => {
				iframe.contentWindow.postMessage(
					JSON.stringify({
						screen: type,
						lessonContent,
						questionContent,
						activeOrgData,
						teacherLearnerImgs,
						languageCodes,
						pointer
					}),
					process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL
				)
			}, 300)
		})
	}, [type, lessonContent, questionContent, activeOrgData, pointer])

	return (
		<>
			<img className="PhoneContainer" src={Phone} alt={"phone"} />
			<div className="AppContainer">
				<iframe
					id="previewFrame"
					title="preview for course"
					src={process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL + "/demo"}
					style={{ height: 580, width: 336 }}
				></iframe>
			</div>
		</>
	)
}

export default PreviewFrame
