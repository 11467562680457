import { StringMap } from "./types"

export type UrlFunction = (params?: StringMap) => string

const buildUrl = (rawPath: string): UrlFunction => {
	return (params) => {
		const matches: RegExpMatchArray | null = rawPath.match(/:[a-zA-Z]+/g)

		let builtPath = rawPath.slice(0)
		if (matches && matches.length > 0 && params) {
			matches.forEach((match) => {
				const key = match.replace(":", "")
				builtPath = builtPath.replace(match, params[key])
			})
		}

		return builtPath
	}
}

export type Method = "GET" | "POST" | "PUT" | "DELETE"

export interface Route {
	url: UrlFunction
	method: Method
	noAuthHeader?: boolean
	isMultipart?: boolean
}

const Routes = {
	getCreateStageDefaults: { url: buildUrl("/api/courses/modules/create-stage/defaults"), method: "GET" },
	getVoiceProfileAIDefaults: { url: buildUrl("/api/voice-profiles/defaults"), method: "GET" },
	requestVoiceClips: { url: buildUrl("/api/courses/stage/:stageId/voice-clip-request"), method: "POST" },
	processAudioFile: {
		url: buildUrl("/api/courses/stage/:stageId/process-audio-file"),
		method: "POST",
		isMultipart: true
	},
	userSearch: { url: buildUrl("/api/userManagement/:orgId/users/search"), method: "POST" },
	userFindById: { url: buildUrl("/api/userManagement/:orgId/users/:userId"), method: "GET" },
	userCount: { url: buildUrl("/api/userManagement/:orgId/users/count"), method: "POST" },
	userDelete: { url: buildUrl("/api/userManagement/:orgId/users/delete"), method: "POST" },
	userSearchOptions: { url: buildUrl("/api/userManagement/:orgId/users/options"), method: "POST" },
	shareRequestSenders: { url: buildUrl("/api/share-requests/:orgId/find-senders"), method: "GET" },
	stageAddDefaultCard: { url: buildUrl("/api/courses/stage/add-default-card"), method: "POST" },
	stageCopyCard: { url: buildUrl("/api/courses/stage/copy-card"), method: "POST" },
	credit: {
		checkBalance: { url: buildUrl("/api/credit/:orgId/check"), method: "GET" },
		transactions: { url: buildUrl("/api/credit/:orgId/search"), method: "POST" }
	},
	payments: {
		add: { url: buildUrl("/api/payments/:orgId/add"), method: "POST" }
	}
} as const

export default Routes
