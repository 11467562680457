import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectStage } from "../../../../redux/courses/courses.selectors"
import { notifyCardAdded } from "../../../../redux/courses/courses.actions"

import Text from "./cardTypeImages/Text"
import Image from "./cardTypeImages/Image"
import CallOut from "./cardTypeImages/CallOut"
import Video from "./cardTypeImages/Video"
import Survey from "./cardTypeImages/Survey"
import Url from "./cardTypeImages/Url"
import { Modal } from "antd"
// @ts-ignore
import MultChoiceQuestion from "./cardTypeImages/mult_choice_icon.svg"
// @ts-ignore
import BooleanQuestion from "./cardTypeImages/boolean_icon.svg"

import * as S from "../../../styles/PageStyles"
import { Card, CardType } from "learnink-common/lib/domains/cards/Card.model"
import { QuestionStyle } from "learnink-common/lib/domains/cards/questions/BaseQuestionCard.model"
import { AddDefaultCardParams } from "learnink-common/lib/domains/courses/stages/Stage.model"
import { useApi } from "../../../../util/useApi"
import Routes from "../../../../util/Routes"
import { selectOrgId } from "../../../../redux/org/org.selectors"

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const ItemContainer = styled.div`
	width: 165px;
	height: 165px;
	padding: 10px;
	cursor: pointer;
`
const Item = styled.div`
	width: 100%;
	height: 100%;
	border: solid 1px var(--light-grey);
	transition: 0.3s;
	background: var(--v-light-grey);
	:hover {
		background: var(--chat-bubble-left);
		border: solid 1px var(--secondary-color);
	}
`
const ItemContent = styled.div`
	width: 100%;
	height: 114px;
`
const ItemFooter = styled.div`
	width: 100%;
	color: black;
	text-align: center;
	border-top: solid 1px var(--light-grey);
	font-size: 12px;
	background-color: white;
	line-height: 28px;
`
const Icon = styled.img`
	width: 100px;
	height: 100px;
`
const lessonCardTypes: CardType[] = ["text", "image", "multipleChoiceSurvey", "callOut", "button", "video"]
const questionStyles: QuestionStyle[] = ["multChoiceImage", "booleanImage"]

//	TODO - where do we get these values from?
const cardTitles = {
	text: "Message",
	image: "Image",
	multipleChoiceSurvey: "Survey",
	callOut: "Call Out",
	button: "Button",
	video: "Video",
	multChoiceImage: "Mult. choice",
	booleanImage: "True/false"
}

type LessonOrQuestion = "lesson" | "question"

interface Props {
	closeModal: any
	orgId: string
	stage: {
		stage_id: string
		teacherLearnerImgs: {
			teacherImg: string
		}
	}
	lessonOrQuestion: LessonOrQuestion
	notifyCardAdded: (card: Card, lessonOrQuestion: LessonOrQuestion, index?: number) => void
	index?: number
}

const SelectCardType = ({ closeModal, orgId, stage, lessonOrQuestion, notifyCardAdded, index }: Props) => {
	const { setApiRequest: addDefaultCard } = useApi<AddDefaultCardParams, Card>()

	const handleAddDefaultCard = (type: CardType, index?: number, params?: { style: QuestionStyle }) => {
		addDefaultCard({
			route: Routes.stageAddDefaultCard,
			payload: { type, stageId: stage.stage_id, orgId, index, params },
			onSuccess: (card) => {
				notifyCardAdded(card, lessonOrQuestion, index)
				closeModal()
			}
		})
	}

	return (
		<Modal
			title={lessonOrQuestion === "lesson" ? "Select card type" : "Select question type"}
			footer={null}
			open={true}
			centered
			onCancel={closeModal}
			width={560}
		>
			<Wrapper>
				{lessonOrQuestion === "lesson" && (
					<>
						{lessonCardTypes.map((type) => (
							<ItemContainer
								key={type}
								onClick={() => {
									handleAddDefaultCard(type, index)
								}}
							>
								<Item>
									<ItemContent>
										{type === "text" && <Text teacherImg={stage.teacherLearnerImgs.teacherImg} />}
										{type === "image" && <Image teacherImg={stage.teacherLearnerImgs.teacherImg} />}
										{type === "multipleChoiceSurvey" && (
											<Survey teacherImg={stage.teacherLearnerImgs.teacherImg} />
										)}
										{type === "callOut" && (
											<CallOut teacherImg={stage.teacherLearnerImgs.teacherImg} />
										)}
										{type === "button" && <Url teacherImg={stage.teacherLearnerImgs.teacherImg} />}
										{type === "video" && <Video teacherImg={stage.teacherLearnerImgs.teacherImg} />}
									</ItemContent>
									{/* @ts-ignore */}
									<ItemFooter>{cardTitles[type]}</ItemFooter>
								</Item>
							</ItemContainer>
						))}
					</>
				)}
				{lessonOrQuestion === "question" && (
					<>
						{questionStyles.map((style) => (
							<ItemContainer
								key={style}
								onClick={() => {
									handleAddDefaultCard(CardType.enum.multipleChoiceQuestion, index, { style })
								}}
							>
								<Item>
									<ItemContent>
										{style === "multChoiceImage" && (
											<S.FlexBoxColumnCentered>
												<Icon src={MultChoiceQuestion} />
											</S.FlexBoxColumnCentered>
										)}
										{style === "booleanImage" && (
											<S.FlexBoxColumnCentered>
												<Icon src={BooleanQuestion} />
											</S.FlexBoxColumnCentered>
										)}
									</ItemContent>
									{/* @ts-ignore */}
									<ItemFooter>{cardTitles[style]}</ItemFooter>
								</Item>
							</ItemContainer>
						))}
					</>
				)}
			</Wrapper>
		</Modal>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage,
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch: any) => ({
	notifyCardAdded: (card: Card, lessonOrQuestion: LessonOrQuestion, index?: number) =>
		dispatch(notifyCardAdded(card, lessonOrQuestion, index))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SelectCardType)
