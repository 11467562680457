import React from "react"
import { Button, Tooltip } from "antd"
import RecordAudioModal, { useRecordAudioProps } from "./useRecordAudio"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { selectPermissions } from "../../../redux/permissions/permissions.selectors"
import { Permissions } from "learnink-common/lib/domains/permissions/Permission.tools"
import { createStructuredSelector } from "reselect"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"
import { connect } from "react-redux"
import { AudioFilled } from "@ant-design/icons"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { languageColors } from "../../styles/PageStyles"

interface Props {
	textId: string | undefined
	description?: string
	card: any
	languageCode: LanguageCode
	permissions: Permissions
	voiceClip: VoiceClip | undefined
}

const RecordAudioButton = ({ textId, description, card, languageCode, permissions, voiceClip }: Props) => {
	const { modalIsOpen, setModalIsOpen } = useRecordAudioProps()

	return (
		<>
			{permissions.canEdit(Resource.enum.VOICE_CLIP_RECORDING) && (
				<>
					{voiceClip ? (
						<Tooltip title="Press to re-create audio">
							<Button
								icon={<AudioFilled />}
								type="default"
								disabled={!textId}
								shape="circle"
								onClick={() => setModalIsOpen(true)}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Press to record or upload an audio clip">
							<Button
								icon={<AudioFilled />}
								type="primary"
								disabled={!textId}
								shape="round"
								onClick={() => setModalIsOpen(true)}
								style={{ backgroundColor: languageColors(languageCode) }}
							>
								Create
							</Button>
						</Tooltip>
					)}
					{textId && (
						<RecordAudioModal
							textId={textId}
							card={card}
							languageCode={languageCode}
							modalIsOpen={modalIsOpen}
							setModalIsOpen={setModalIsOpen}
							textToRecord={description}
						/>
					)}
				</>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	permissions: selectPermissions
})

export default connect(mapStateToProps)(RecordAudioButton)
