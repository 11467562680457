import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import moment from "moment"

import useUrlQueryString from "../../../hooks/useUrlQueryString"
import * as S from "../../../styles/PageStyles"
import { userTableColumns } from "../../../management/userTable/UserTable.model"

import { selectOrgId, selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"
import { fetchAnalytics } from "../../fetchAnalytics"
import UserTableWrapper from "../../../management/userTable/UserTableWrapper"
import { LearningPathUser, LearningPathUserStatus } from "learnink-common/lib/domains/userManagement/users/User.model"
import CbLoading from "../../../common/CbLoading"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../../pages/learningPaths/utils/LearningPathContent"
import CohortTable from "./CohortTable"
import { OrgUserGroupNamesMap } from "../../../../util/model/OrgSelectors"
import { DASH_DATE_FORMAT } from "learnink-common/lib/constants/constants"
import UserGroupSummaryTable from "./UserGroupSummaryTable"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"
import CourseCompletionChart from "./CourseCompletionChart"
import { calculateAverage } from "../../../../util/formatters"

interface Props {
	orgId: string
	orgUserGroupNamesMap: OrgUserGroupNamesMap
	learningPath: LearningPath
}

export interface LearningPathUserTableRow extends LearningPathUser {
	userGroupNames: string[]
	status: LearningPathUserStatus
	dueDate: string
	startWeek: string
}

const LearningPathProgress = ({ orgId, orgUserGroupNamesMap, learningPath }: Props) => {
	const [dateRange] = useUrlQueryString("dateRange")
	const [userGroups] = useUrlQueryString("userGroups")
	let userGroupsArr = typeof userGroups === "string" ? userGroups.split(",") : []
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [learningPathUserTableData, setLearningPathUserTableData] = useState<LearningPathUserTableRow[] | undefined>(
		undefined
	)
	const numDays = new LearningPathContent(learningPath).getLengthInDays()

	let filteredByUserGroup = learningPathUserTableData
	if (userGroupsArr.length > 0 && filteredByUserGroup) {
		filteredByUserGroup = filteredByUserGroup
			.filter((x) => x["userGroups"])
			// @ts-ignore
			.filter((x) => userGroupsArr.filter((value) => x["userGroups"].includes(value)).length > 0)
	}

	const getLearningPathUserTableData = async () => {
		try {
			const data = await fetchAnalytics(
				"userManagement",
				orgId,
				`users/learning-path/${learningPath.id}`,
				// @ts-ignore
				dateRange
			)
			data.forEach((row: LearningPathUserTableRow) => {
				const dueTimestamp = moment(row.startedAt).add(numDays, "d").startOf("day")
				row.userGroupNames = row.userGroups
					? row.userGroups.map((item: string) => orgUserGroupNamesMap[item])
					: []
				row.dueDate = moment(row.startedAt)
					.add(numDays - 1, "d")
					.format(DASH_DATE_FORMAT)
				row.status =
					row.completedAt && moment(row.completedAt).diff(dueTimestamp) < 0
						? "completed on time"
						: row.completedAt
						? "completed late"
						: moment().endOf("day").diff(dueTimestamp) < 0
						? "in progress"
						: "overdue"
				row.startWeek = moment(row.startedAt).startOf("week").format(DASH_DATE_FORMAT)
			})
			setLearningPathUserTableData(data)
			setIsLoading(false)
		} catch (err) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (dateRange) {
			setIsLoading(true)
			getLearningPathUserTableData()
		}
	}, [dateRange, learningPath])

	if (isLoading) {
		return <CbLoading fullpage />
	}

	if (!filteredByUserGroup) {
		return <div>Error</div>
	}

	return (
		<>
			<div className="row" style={{ marginBottom: 10 }}>
				<div className="col-sm-12">
					<UserGroupSummaryTable data={filteredByUserGroup} userGroupsArr={userGroupsArr} />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-3">
					<ChartWrapper tooltip="" title="Av. num courses completed">
						<SingleValueChart>
							{calculateAverage(filteredByUserGroup, "numCoursesCompleted", 1)}
						</SingleValueChart>
					</ChartWrapper>
					<ChartWrapper tooltip="" title="Av. score">
						<SingleValueChart>{calculateAverage(filteredByUserGroup, "userScore", 0)}%</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-9">
					<CourseCompletionChart learningPath={learningPath} data={filteredByUserGroup} showTotalUsers />
				</div>
			</div>
			<div className="row" style={{ marginBottom: 20 }}>
				<div className="col-sm-12">
					<CohortTable data={filteredByUserGroup} />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<S.Card>
						<S.CardBody>
							{/* @ts-ignore */}
							<UserTableWrapper
								data={filteredByUserGroup}
								columns={[
									"customName",
									"userGroupNames",
									"shareRequestSenders",
									"startedAt",
									"status",
									"numCoursesCompleted",
									"userScore",
									"completedAt",
									"dueDate",
									"lastActive",
									"firstName",
									"lastName",
									"phoneNumber",
									"customId",
									"country",
									"region",
									"ageBracket",
									"sex",
									"registeredAt"
								].map((item) => userTableColumns.filter((column) => column.id === item)[0])}
								actions={["notify", "export", "editLearningPath"]}
								notifyButtonOn
								hiddenColumns={[
									"firstName",
									"lastName",
									"phoneNumber",
									"customId",
									"country",
									"region",
									"ageBracket",
									"sex",
									"registeredAt",
									"shareRequestSenders"
								]}
								csvFileName="learning_path_progress"
							/>
						</S.CardBody>
					</S.Card>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroupNamesMap: selectOrgUserGroupNamesMap
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathProgress)
