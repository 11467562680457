import { createSelector } from "reselect"
import { selectUserInfo } from "../user/user.selectors"
import { UserInfo } from "../../App"
import { selectOrgId } from "../org/org.selectors"
import { Permissions } from "learnink-common/lib/domains/permissions/Permission.tools"

export const selectPermissions = createSelector(
	[selectUserInfo, selectOrgId],
	(userInfo: UserInfo, orgId: string) => new Permissions(userInfo.permissions, orgId)
)
