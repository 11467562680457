import { UploadProps } from "antd"
import React, { useEffect, useState } from "react"
import { UploadOutlined } from "@ant-design/icons"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"
import { connect } from "react-redux"
import { selectUserInfo } from "../../../redux/user/user.selectors"
import { setQuestionState, setLessonCardState } from "../../../redux/courses/courses.actions"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { QuestionCard } from "learnink-common/lib/domains/cards/questions/QuestionCard.model"
import Dragger from "antd/lib/upload/Dragger"
import CbLoading from "../../common/CbLoading"
import { languageColors } from "../../styles/PageStyles"

interface RecordAudioModalProps {
	textId: string
	userInfo: { user_id: string }
	orgId: string
	stageId: string
	card: Card
	languageCode: LanguageCode
	setLessonCard: (updateParams: LessonCard) => void
	setQuestion: (updateParams: QuestionCard) => void
	setFileUploadMessage: any
	setFileUploadError: any
}

interface ProcessAudioParams {
	userId: string
	orgId: string
	stageId: string
	cardId: string
	languageCode: LanguageCode
	source: Blob
}

const DragToUploadAudio = ({
	textId,
	userInfo,
	orgId,
	stageId,
	card,
	languageCode,
	setLessonCard,
	setQuestion,
	setFileUploadMessage,
	setFileUploadError
}: RecordAudioModalProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [blob, setBlob] = useState<Blob | undefined>(undefined)
	const [fileName, setFileName] = useState<string | undefined>(undefined)
	const { setApiRequest: processAudioFile } = useApi<ProcessAudioParams, VoiceClip>()

	//	 TODO - fix once we have full card types
	const refreshCard = card.type !== "multipleChoiceQuestion" ? setLessonCard : setQuestion

	const updateVoiceClips = (voiceClips: VoiceClip[], voiceClip: VoiceClip) => {
		const updatedVoiceClips = [...voiceClips].filter((item) => item.languageCode !== languageCode)
		updatedVoiceClips.push(voiceClip)
		return updatedVoiceClips
	}

	const updateCardVoiceClip = (voiceClip: VoiceClip): Card => {
		const newCard = { ...card }
		//	Initialise card
		newCard.voiceClips = [...(newCard?.voiceClips || [])]

		// If the text ids match then we're updating the main part of the card
		if (newCard.textId === voiceClip.textId) {
			newCard.voiceClips = updateVoiceClips(newCard.voiceClips, voiceClip)
		}
		if (newCard.textId !== voiceClip.textId && newCard.type === "multipleChoiceSurvey") {
			newCard.surveyOptions = newCard.surveyOptions || []
			newCard.surveyOptions.forEach((option, index) => {
				if (option.textId === voiceClip.textId) {
					// @ts-ignore
					newCard.surveyOptions[index].voiceClips = updateVoiceClips(
						// @ts-ignore
						newCard.surveyOptions[index].voiceClips,
						voiceClip
					)
				}
			})
		}
		if (newCard.textId !== voiceClip.textId && newCard.type === "multipleChoiceQuestion") {
			newCard.answerOptions = newCard.answerOptions || []
			newCard.answerOptions.forEach((option, index) => {
				if (option.textId === voiceClip.textId) {
					// @ts-ignore
					newCard.answerOptions[index].voiceClips = updateVoiceClips(
						// @ts-ignore
						newCard.answerOptions[index].voiceClips,
						voiceClip
					)
				}
			})
		}
		return newCard
	}

	const handleProcessAudio = () => {
		const formData = getFormData()
		processAudioFile({
			route: Routes.processAudioFile,
			urlParams: { stageId },
			formData,
			onSuccess: (voiceClip) => {
				refreshCard(updateCardVoiceClip(voiceClip) as unknown as never)
				setBlob(undefined)
				setFileUploadMessage(fileName)
				setIsLoading(false)
			},
			onError: (err) => {
				console.log(err)
				setBlob(undefined)
				setIsLoading(false)
				setFileUploadMessage("Upload failed")
				setFileUploadError(true)
			}
		})
	}

	const getFormData = (): FormData => {
		if (!blob) throw new Error("source needs to be created first...")

		const formData = new FormData()
		formData.set("userId", userInfo?.user_id)
		formData.set("orgId", orgId)
		formData.set("stageId", stageId)
		formData.set("textId", textId)
		formData.set("languageCode", languageCode)
		formData.set("source", blob, "defaultFilename." + blob.type)

		return formData
	}

	useEffect(() => {
		if (blob) {
			handleProcessAudio()
		}
	}, [blob])

	const handleFileChange: UploadProps["onChange"] = (info) => {
		setIsLoading(true)
		const file = info.file.originFileObj || info.file // Access the file object directly
		if (file instanceof File) {
			const reader = new FileReader()

			reader.onloadend = () => {
				// Create a blob from the result
				const audioBlob = new Blob([reader.result as ArrayBuffer], { type: file.type })
				setBlob(audioBlob)
			}

			// Read the file as an ArrayBuffer
			reader.readAsArrayBuffer(file)
			setFileName(info.file.name)
		}
	}

	useEffect(() => {
		setFileName(undefined)
	}, [textId])

	const uploadProps: UploadProps = {
		accept: "audio/*", // Restrict file types to audio
		beforeUpload: () => false, // Disable automatic upload
		onChange: handleFileChange, // Handle file change manually
		showUploadList: false,
		multiple: false
	}

	if (isLoading)
		return (
			<div style={{ width: "100%", height: 117, background: "#fafafa", border: "1px dashed #d9d9d9" }}>
				<CbLoading size={30} />
			</div>
		)

	return (
		<Dragger {...uploadProps}>
			<UploadOutlined style={{ color: languageColors(languageCode), fontSize: 28, marginBottom: 6 }} />
			<p className="ant-upload-text">Drag file here</p>
			<p className="ant-upload-hint">Click or drag file to this area to upload</p>
		</Dragger>
	)
}

const mapStateToProps = createStructuredSelector({
	userInfo: selectUserInfo,
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

const mapDispatchToProps = (dispatch: any) => ({
	setQuestion: (card: QuestionCard) => dispatch(setQuestionState(card)),
	setLessonCard: (card: LessonCard) => dispatch(setLessonCardState(card))
})

export default connect(mapStateToProps, mapDispatchToProps)(DragToUploadAudio)
