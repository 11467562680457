import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../redux/org/org.selectors"
import { connect } from "react-redux"
import React, { CSSProperties, useEffect, useState } from "react"
import { initializePaddle, Paddle, PaddleEventData } from "@paddle/paddle-js"
import { DashboardNav, FilterContainer } from "../../components/analyse/nav/AnalysisNavWithFilters"
import TimeFilter from "../../components/analyse/nav/TimeFilter"
import styled from "styled-components"
import * as S from "../../components/styles/PageStyles"
import { Button } from "antd"
import { PropertySafetyOutlined, TransactionOutlined } from "@ant-design/icons"
import {
	CartesianGrid,
	Cell,
	Legend,
	Line,
	LineChart,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts"
import { useApi } from "../../util/useApi"
import {
	SearchTransactionFilter,
	Balance,
	Transaction,
	CheckBalanceParams
} from "learnink-common/lib/domains/credit/CreditTransaction.model"
import { CreditType } from "learnink-common/lib/domains/credit/CreditCost.model"
import { Payment, Type } from "learnink-common/lib/domains/payments/Payment.model"
import { PaddleV1Metadata } from "learnink-common/lib/domains/payments/paddle/Paddle.model"
import Routes from "../../util/Routes"
import { FlexBoxStartRow } from "../../components/styles/PageStyles"
import moment from "moment"
import { CheckoutEventsData } from "@paddle/paddle-js/types/checkout/events"
import { selectUserInfo } from "../../redux/user/user.selectors"
import { UserInfo } from "../../App"
import { selectPermissions } from "../../redux/permissions/permissions.selectors"
import { Permissions } from "learnink-common/lib/domains/permissions/Permission.tools"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"
import FeatureNoAccess from "../landing/FeatureNoAccess"
import _ from "lodash"

const _S = {
	background: styled.div`
		width: 100%;
		min-height: 100vh;
		height: 100%;
		background: #f6f9fc;
		@media screen and (max-width: 850px) {
			padding: 20px 10px 40px 10px;
		}
		@media screen and (min-width: 850px) {
			padding: 80px 10px 40px 10px;
		}
	`,
	Card: ({ children }: { children: React.ReactNode }) => (
		<S.Card>
			<S.CardBody>{children}</S.CardBody>
		</S.Card>
	),
	transactions: styled(FlexBoxStartRow)`
		gap: 20px;
	`,
	cardTitle: styled.div`
		font-size: 1.1rem;
		font-weight: bold;
		margin-left: 10px;
	`,
	Divider: styled.div`
		padding: 0;
		margin-top: 12px;
		margin-bottom: 4px;
		border-bottom: 1px solid var(--light-grey);
	`,
	pieChartProps: (pieChartSlices: PieChartSlice[]) => ({
		isAnimationActive: true,
		data: pieChartSlices,
		nameKey: "feature",
		dataKey: "credits",
		innerRadius: 40,
		outerRadius: 90,
		paddingAngle: 0
	})
}

interface Props {
	orgId: string
	user: UserInfo
	permissions: Permissions
}

const CreditPage = ({ orgId, user, permissions }: Props) => {
	const [searchFilters, setSearchFilters] = useState<SearchTransactionFilter>({ orgId })

	const { setApiRequest: checkBalanceRequest, data: balance } = useApi<CheckBalanceParams, Balance>()

	const {
		setApiRequest: searchTransactionsRequest,
		isLoading: transactionsIsLoading,
		data: transactions
	} = useApi<SearchTransactionFilter, Transaction[]>()

	const api = {
		checkBalance: () => {
			checkBalanceRequest({
				route: Routes.credit.checkBalance,
				urlParams: { orgId }
			})
		},
		searchTransactions: () => {
			searchTransactionsRequest({
				route: Routes.credit.transactions,
				urlParams: { orgId },
				payload: searchFilters
			})
		}
	}

	const refreshTransactions = () => {
		const REFRESH_TRANSACTIONS_MILLIS = 10000
		const _refreshTransactions = () => {
			api.checkBalance()
			api.searchTransactions()
		}

		_refreshTransactions()

		const refresh = setInterval(_refreshTransactions, REFRESH_TRANSACTIONS_MILLIS)
		return () => {
			clearInterval(refresh)
		}
	}

	useEffect(refreshTransactions, [])

	const checkout = useCheckout({ orgId, userId: user.user_id })

	const canViewCredit = permissions.canView(Resource.enum.CREDIT)
	if (!canViewCredit) return <FeatureNoAccess featureTitle="Credits" />

	const pieChartSlices = getPieChartSlices(transactions)
	const runningCreditTotal = getRunningCreditTotalsByDate(transactions)

	const CreditActions = (
		<_S.Card>
			<S.FlexBoxRowSpaceBetween>
				<_S.cardTitle>
					{balance && (
						<div>
							You have <span style={{ color: "#27c940", fontWeight: "bold" }}>{balance.credits}</span>{" "}
							credits remaining{" "}
							<span style={{ fontSize: "0.8rem", fontWeight: "normal", fontStyle: "italic" }}>
								(last updated {moment(balance.timestamp).format("HH:mm")})
							</span>
						</div>
					)}
				</_S.cardTitle>
				<Button onClick={checkout.open} type="primary" shape="round">
					<TransactionOutlined />
					Add credits...
				</Button>
			</S.FlexBoxRowSpaceBetween>
		</_S.Card>
	)

	const UsageCharts = (
		<_S.Card>
			<_S.cardTitle>Usage insights</_S.cardTitle>

			<FlexBoxStartRow>
				<ResponsiveContainer width="45%" height={250}>
					<PieChart>
						<Pie {..._S.pieChartProps(pieChartSlices)}>
							{pieChartSlices.map((e, index) => (
								<Cell key={index} fill={e.color} />
							))}
						</Pie>
						<Legend />
						<Tooltip />
					</PieChart>
				</ResponsiveContainer>

				<ResponsiveContainer width="45%" height={250}>
					<LineChart data={runningCreditTotal}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" tickFormatter={(isoDate: string) => moment(isoDate).format("D MMM")} />
						<YAxis />
						<Legend />
						<Tooltip />
						<Line type="monotone" dataKey="credits" stroke="#82ca9d" />
					</LineChart>
				</ResponsiveContainer>
			</FlexBoxStartRow>
		</_S.Card>
	)

	const Transaction = ({ transaction }: { transaction: Transaction }) => {
		const date = moment(transaction.createdAt)

		const backgroundColour = transaction.credits > 0 ? "#2cc0433d" : "#F72C5B3d"
		const borderColour = transaction.credits > 0 ? "#27c9403d" : "#e426523d"
		const fontColour = transaction.credits > 0 ? "#2cc043" : "#F72C5B"

		const timeBoxStyle: CSSProperties = {
			background: backgroundColour,
			borderRadius: "4px",
			border: "2px solid " + borderColour,
			padding: "4px 10px",
			textAlign: "center",
			width: "120px",
			color: fontColour,
			fontWeight: "bold"
		}

		const creditBoxStyle: CSSProperties = {
			width: "100px",
			fontSize: "1.3rem",
			color: fontColour,
			fontWeight: "bold",
			textAlign: "right"
		}

		return (
			<div style={{ padding: "5px" }}>
				<_S.transactions>
					<div style={timeBoxStyle}>
						<div>{date.format("HH:mm")}</div>
						<div>{date.format("Do MMM")}</div>
					</div>

					<div style={{ width: "130px" }}>{transaction.type.replaceAll("_", " ")}</div>

					<div style={{ width: "400px" }}>
						<div>Ref:</div>
						<div style={{ width: "400px", fontFamily: "monospace" }}>{transaction.transactionRef}</div>
					</div>

					<div>{transaction.createdByName}</div>

					<div style={creditBoxStyle}>
						{transaction.credits} <PropertySafetyOutlined />
					</div>
				</_S.transactions>
				<_S.Divider />
			</div>
		)
	}

	const Transactions = (
		<_S.Card>
			<_S.cardTitle>Transactions</_S.cardTitle>

			{transactions?.map((transaction) => (
				<Transaction transaction={transaction} key={transaction.id} />
			))}
		</_S.Card>
	)

	return (
		<>
			<DashboardNav>
				<FilterContainer>
					<TimeFilter />
				</FilterContainer>
			</DashboardNav>

			<_S.background>
				{CreditActions}

				{UsageCharts}

				{Transactions}
			</_S.background>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	user: selectUserInfo,
	permissions: selectPermissions
})

// @ts-ignore
export default connect(mapStateToProps)(CreditPage)

interface Checkout {
	open: () => void
}

interface CheckoutParams {
	orgId: string
	userId: string
}

function useCheckout({ orgId, userId }: CheckoutParams): Checkout {
	const [paddle, setPaddle] = useState<Paddle>()
	const { setApiRequest: addPayment } = useApi<Payment, Payment>()

	useEffect(() => {
		initializePaddle({
			environment: "sandbox",
			token: "test_fda76d41888b0c65ed243d4c598",
			eventCallback: (event: PaddleEventData) => {
				if (event?.name === "checkout.completed") {
					const completedEvent = event.data as CheckoutEventsData
					addPayment({
						route: Routes.payments.add,
						urlParams: { orgId },
						payload: {
							orgId,
							type: Type.enum.PADDLE_V1,
							metadata: event as PaddleV1Metadata,
							amount: completedEvent.totals.total,
							currencyCode: completedEvent.currency_code,
							transactionRef: completedEvent.transaction_id,
							createdBy: userId
						}
					})
				}
			}
		}).then((paddle) => {
			if (paddle) setPaddle(paddle)
		})
	}, [])

	return {
		open: () => {
			paddle?.Checkout.open({
				items: [{ priceId: "pri_01jes5zfk474n81p3zv7ed78yv" }]
			})
		}
	}
}

const CREDIT_TYPE_COLOUR: { [key in CreditType]: string } = {
	ADD_CREDIT: "#6ede8a",
	TEXT_TO_SPEECH: "#208b3a",
	TEXT_TO_COURSE: "#2dc653"
}

function sumCredits(transactions: Transaction[]): number {
	return transactions.map((t) => t.credits).reduce((a, b) => a + b)
}

function absoluteSumCredits(transactions: Transaction[]): number {
	return transactions.map((t) => Math.abs(t.credits)).reduce((a, b) => a + b)
}

interface PieChartSlice {
	feature: string
	credits: number
	color: string
}

function getPieChartSlices(transactions: Transaction[] | undefined): PieChartSlice[] {
	return _.chain(transactions || [])
		.filter((t) => t.type !== CreditType.enum.ADD_CREDIT)
		.groupBy((t) => t.type)
		.entries()
		.map(([creditType, transactionsByType]) => ({
			feature: creditType.replaceAll("_", " "),
			credits: absoluteSumCredits(transactionsByType),
			color: CREDIT_TYPE_COLOUR[creditType as CreditType]
		}))
		.value()
}

interface Plot {
	date: string
	credits: number
}

function getRunningCreditTotalsByDate(transactions: Transaction[] | undefined): Plot[] {
	return _.chain([...(transactions || [])])
		.groupBy((t) => moment(t.createdAt).startOf("day").toISOString())
		.entries()
		.sortBy(([date, _]) => date)
		.map(([date, transactionsByDate]) => ({
			date,
			credits: sumCredits(transactionsByDate)
		}))
		.forEach(({ date, credits }, index, array) => {
			const previousCredits = array[index - 1]?.credits ?? 0
			array[index].credits += previousCredits
		})
		.value()
}
