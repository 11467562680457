import React from "react"
import BasicTable from "../../common/BasicTable"

const SequenceSummaryTable = ({ sequenceList, queryData }) => {
	const tableData = []
	queryData.forEach((row) => {
		const sequenceLookUp = sequenceList.filter((item) => item.id === row.sequenceId && item.isActive)
		if (sequenceLookUp.length > 0) {
			const sequence = sequenceLookUp[0]
			tableData.push({
				sequenceId: sequence.id,
				sequenceName: sequence.internalName,
				sequenceImg:
					sequence.image ||
					"https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80",
				numStarted: row.numStarted,
				numCompleted: row.numCompleted,
				conversion: row.numStarted > 0 ? Math.round((row.numCompleted / row.numStarted) * 100) : "--"
			})
		}
	})

	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "Sequence name",
						accessor: "sequenceName"
					},
					{
						Header: "Num started",
						accessor: "numStarted"
					},
					{
						Header: "Num completed",
						accessor: "numCompleted"
					},
					{
						Header: "% conversion",
						accessor: "conversion"
					}
				]}
				data={tableData}
				csvFileName="progress_by_sequence"
			/>
		</div>
	)
}

export default SequenceSummaryTable
