import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"

import BasicTable from "../../common/BasicTable"

const UserGroupSummaryTable = ({ queryData, userGroupsArr, orgUserGroupsNames }) => {
	const tableData = []
	userGroupsArr.forEach((userGroup) => {
		const userGroupName = orgUserGroupsNames[userGroup]
		if (queryData.filter((item) => item.userGroup === userGroup).length > 0) {
			const userGroupData = queryData.filter((item) => item.userGroup === userGroup)[0]
			tableData.push({
				userGroupName,
				numActive: userGroupData.numActive,
				numRegistered: userGroupData.numRegistered,
				numStartedCourse: userGroupData.numStartedCourse,
				numCompletedCourse: userGroupData.numCompletedCourse
			})
		} else {
			tableData.push({
				userGroupName,
				numActive: 0,
				numRegistered: 0,
				numStartedCourse: 0,
				numCompletedCourse: 0
			})
		}
	})
	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "User group",
						accessor: "userGroupName"
					},
					{
						Header: "Num active",
						accessor: "numActive"
					},
					{
						Header: "Num registered",
						accessor: "numRegistered"
					},
					{
						Header: "Num started a course",
						accessor: "numStartedCourse"
					},
					{
						Header: "Num completed a course",
						accessor: "numCompletedCourse"
					}
				]}
				data={tableData}
				csvFileName="activity_summary"
			/>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsNames: selectOrgUserGroupNamesMap
})

export default connect(mapStateToProps)(UserGroupSummaryTable)
