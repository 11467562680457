import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"

import BasicTable from "../../common/BasicTable"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"

const UserGroupSummaryTable = ({ queryData, userGroupsArr, orgUserGroupsNames }) => {
	if (userGroupsArr.length > 1) {
		const tableData = []
		userGroupsArr.forEach((userGroup) => {
			const userGroupName = orgUserGroupsNames[userGroup]
			if (queryData.filter((item) => item.userGroup === userGroup).length > 0) {
				const userGroupData = queryData.filter((item) => item.userGroup === userGroup)[0]
				tableData.push({
					userGroupName,
					numSequencesStarted: userGroupData.numSequencesStarted,
					numSequencesCompleted: userGroupData.numSequencesCompleted,
					conversion:
						userGroupData.numSequencesStarted > 0
							? Math.round(
									(userGroupData.numSequencesCompleted / userGroupData.numSequencesStarted) * 100
							  )
							: undefined
				})
			} else {
				tableData.push({
					userGroupName,
					numSequencesStarted: 0,
					numSequencesCompleted: 0,
					conversion: null
				})
			}
		})
		return (
			<div className="row" style={{ padding: 15 }}>
				<BasicTable
					columns={[
						{
							Header: "User group",
							accessor: "userGroupName"
						},
						{
							Header: "Total sequences started",
							accessor: "numSequencesStarted"
						},
						{
							Header: "Total sequences completed",
							accessor: "numSequencesCompleted"
						},
						{
							Header: "% conversion",
							accessor: "conversion"
						}
					]}
					data={tableData}
					csvFileName="sequence_progress_summary"
				/>
			</div>
		)
	} else {
		const conversion =
			queryData.length > 0 && queryData[0].numSequencesStarted > 0
				? Math.round((queryData[0].numSequencesCompleted / queryData[0].numSequencesStarted) * 100)
				: "--"
		return (
			<>
				<div className="row">
					<div className="col-sm-4">
						<ChartWrapper
							tooltip="The total number of sequences started in the timeframe selected"
							title={"Num sequences started"}
						>
							<SingleValueChart>
								{queryData.length > 0 ? queryData[0].numSequencesStarted : 0}
							</SingleValueChart>
						</ChartWrapper>
					</div>
					<div className="col-sm-4">
						<ChartWrapper
							tooltip="The total number of sequences started in the timeframe that are now completed"
							title={"Num sequences completed"}
						>
							<SingleValueChart>
								{queryData.length > 0 ? queryData[0].numSequencesCompleted : 0}
							</SingleValueChart>
						</ChartWrapper>
					</div>
					<div className="col-sm-4">
						<ChartWrapper
							tooltip="The % of sequences started in the timeframe that are now completed"
							title={"% conversion"}
						>
							<SingleValueChart>{conversion}%</SingleValueChart>
						</ChartWrapper>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsNames: selectOrgUserGroupNamesMap
})

export default connect(mapStateToProps)(UserGroupSummaryTable)
