import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { Card } from "learnink-common/lib/domains/cards/Card.model"

const isEmpty = (string: string | undefined) => {
	return string === undefined || string.trim().length === 0
}

interface VoiceClipStatus {
	status: "complete" | "incomplete" | "none"
	voiceClip: string | undefined
}

export class CardModel {
	rawContent: Card

	constructor(rawContent: Card) {
		this.rawContent = rawContent || {}
	}

	getVoiceClipButtonStatus(languageCode: LanguageCode): VoiceClipStatus {
		const card = this.rawContent
		if (card.type === "multipleChoiceQuestion") {
			const expectedNumVoiceClips = (card.answerOptions || []).length + 1
			let voiceClips: string[] = []
			if (card.voiceClips && card.voiceClips.filter((item) => item.languageCode === languageCode).length > 0) {
				voiceClips.push(card.voiceClips.filter((item) => item.languageCode === languageCode)[0].fileUrl)
			}
			;(card.answerOptions || []).forEach((answer) => {
				if (
					answer.voiceClips &&
					answer.voiceClips.filter((item) => item.languageCode === languageCode).length > 0
				) {
					voiceClips.push(answer.voiceClips.filter((item) => item.languageCode === languageCode)[0].fileUrl)
				}
			})
			const numVoiceClipFiles = voiceClips.length
			const status =
				numVoiceClipFiles === 0
					? "none"
					: numVoiceClipFiles >= expectedNumVoiceClips
					? "complete"
					: "incomplete"
			return {
				status,
				voiceClip: status === "complete" ? voiceClips[0] : undefined
			}
		}
		if (card.type === "multipleChoiceSurvey") {
			const expectedNumVoiceClips = (card.surveyOptions || []).length + 1
			let voiceClips: string[] = []
			if (card.voiceClips && card.voiceClips.filter((item) => item.languageCode === languageCode).length > 0) {
				voiceClips.push(card.voiceClips.filter((item) => item.languageCode === languageCode)[0].fileUrl)
			}
			;(card.surveyOptions || []).forEach((option) => {
				if (
					option.voiceClips &&
					option.voiceClips.filter((item) => item.languageCode === languageCode).length > 0
				) {
					voiceClips.push(option.voiceClips.filter((item) => item.languageCode === languageCode)[0].fileUrl)
				}
			})
			const numVoiceClipFiles = voiceClips.length
			const status =
				numVoiceClipFiles === 0
					? "none"
					: numVoiceClipFiles >= expectedNumVoiceClips
					? "complete"
					: "incomplete"
			return {
				status,
				voiceClip: status === "complete" ? voiceClips[0] : undefined
			}
		}
		if (card.voiceClips && card.voiceClips.filter((item) => item.languageCode === languageCode).length > 0) {
			return {
				status: "complete",
				voiceClip: card.voiceClips
					.filter((item) => item.languageCode === languageCode)
					.map((item) => item.fileUrl)[0]
			}
		}
		return {
			status: "none",
			voiceClip: undefined
		}
	}

	findErrors() {
		const card = this.rawContent
		const errors: string[] = []

		if (
			["text", "multipleChoiceSurvey", "textInputSurvey", "numberInputSurvey"].includes(card.type) &&
			// @ts-ignore
			isEmpty(card.message)
		) {
			errors.push("Message cannot be blank")
		}
		if (card.type === "image" && isEmpty(card.image)) {
			errors.push("Image cannot be blank")
		}
		if (card.type === "video" && isEmpty(card.videoId)) {
			errors.push("No video file found")
		}
		if (card.type === "video" && !["youtube", "mux", "vimeo"].includes(card.videoType || "")) {
			errors.push("Invalid video type")
		}
		if (card.type === "button" && isEmpty(card.message)) {
			errors.push("Message cannot be blank")
		}
		if (card.type === "button" && isEmpty(card.buttonText)) {
			errors.push("Button title cannot be blank")
		}
		if (card.type === "button" && isEmpty(card.url)) {
			errors.push("Button url cannot be blank")
		}
		if (card.type === "multipleChoiceSurvey" && (card.surveyOptions || []).length === 0) {
			errors.push("Add at least one survey answer")
		}
		if (
			card.type === "multipleChoiceSurvey" &&
			(card.surveyOptions || []).filter((item) => item.text === "" || item.text === undefined).length > 0
		) {
			errors.push("Survey answer can't be blank")
		}
		if (card.type === "multipleChoiceQuestion" && isEmpty(card.text)) {
			errors.push("Question text cannot be blank")
		}
		if (card.type === "multipleChoiceQuestion" && isEmpty(card.image)) {
			errors.push("Question image cannot be blank")
		}
		if (
			card.type === "multipleChoiceQuestion" &&
			(card.answerOptions || []).filter((item) => item.text === "" || item.text === undefined).length > 0
		) {
			errors.push("Question answer can't be blank")
		}
		if (card.type === "multipleChoiceQuestion" && (card.answerOptions || []).length < 2) {
			errors.push("Add at least 2 answers")
		}
		return errors
	}
}
