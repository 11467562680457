// TABLE TO SHOW DATA SPLIT BY USER GROUP

import React from "react"
import styled from "styled-components"
import { useTable, useSortBy } from "react-table"
import { useLocation, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Empty, Tag } from "antd"
import { faCaretDown, faCaretUp, faChartLine } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import { selectOrgUserGroupColorsMap } from "../../../redux/org/org.selectors"
import { chartColors } from "./colors"

import TableExportButton from "./TableExportButton"
import { LineChartOutlined } from "@ant-design/icons"

const TableRow = styled.tr`
	:nth-child(even) {
		background-color: #f6f6f6;
	}
	:hover {
		background-color: var(--secondary-color-pale);
	}
`

const TableContainer = styled.div`
	border: solid 1px var(--v-light-grey);
	width: 100%;
	background-color: white;
	border-radius: 8px;
	padding: 10px;
	padding-top: 30px;
	position: relative;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
`

const MultiTagDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 50px;
`

const columnNames = {
	courseId: "Course ID",
	sequenceId: "Sequence ID",
	learningPathId: "Learning path ID"
}

const BasicTable = ({ columns, data, orgUserGroupColors, csvFileName }) => {
	const { pathname, search } = useLocation()
	const [fixedView] = useUrlQueryString("fixedView")

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy)

	const dataToCsv = () => {
		const accessorToHeaderMap = columns.reduce((map, column) => {
			map[column.accessor] = column.Header
			return map
		}, {})
		const formattedData = data.map((item) => {
			const mappedItem = {}
			Object.keys(item).forEach((key) => {
				const headerName = accessorToHeaderMap[key] || columnNames[key]
				if (headerName) {
					mappedItem[headerName] = item[key]
				}
			})
			return mappedItem
		})
		return formattedData
	}

	if (data.length === 0) {
		return (
			<TableContainer>
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
			</TableContainer>
		)
	}

	return (
		<TableContainer>
			{!fixedView && <TableExportButton data={dataToCsv(data)} filename={csvFileName || "user_group_table"} />}
			<table
				{...getTableProps()}
				style={{
					width: "100%"
				}}
			>
				<thead style={{ textAlign: "left" }}>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									style={{
										color: "black",
										fontWeight: 600,
										fontSize: 16,
										padding: 10
									}}
								>
									{column.render("Header")}
									<span style={{ marginLeft: 5 }}>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<FontAwesomeIcon icon={faCaretDown} />
											) : (
												<FontAwesomeIcon icon={faCaretUp} />
											)
										) : (
											""
										)}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)
						return (
							<TableRow {...row.getRowProps()}>
								{row.cells.map((cell, index) => {
									return (
										<React.Fragment key={index}>
											{cell.column.id === "userGroupName" && (
												<td
													{...cell.getCellProps()}
													style={{
														padding: "10px"
													}}
												>
													<Tag
														key={index}
														color={
															orgUserGroupColors[row.original.userGroupName] ||
															"var(--mid-orange)"
														}
													>
														{row.original.userGroupName}
													</Tag>
												</td>
											)}
											{cell.column.id === "userGroupNames" && (
												<MultiTagDiv>
													<Tag
														key={row.original.userGroupNames[0]}
														color={
															orgUserGroupColors[row.original.userGroupNames[0]]
																? orgUserGroupColors[row.original.userGroupNames[0]]
																: "var(--mid-orange)"
														}
													>
														{row.original.userGroupNames[0]}
													</Tag>
													{row.original.userGroupNames.length > 1 && (
														<Tag key={"plus_tag"}>
															+{row.original.userGroupNames.length - 1}
														</Tag>
													)}
												</MultiTagDiv>
											)}
											{cell.column.id === "sequenceName" && (
												<td {...cell.getCellProps()}>
													<Link
														to={{
															pathname: `${pathname}/sequence`,
															search: `${search}&sequenceId=${row.original.sequenceId}`
														}}
													>
														<div
															style={{
																padding: "10px",
																display: "flex",
																flexDirection: "row",
																alignContent: "center",
																alignItems: "center"
															}}
														>
															<div className="statsButton">
																<FontAwesomeIcon icon={faChartLine} />
															</div>
															<img
																src={row.original.sequenceImg}
																width={50}
																height={50}
																style={{
																	borderRadius: 9999,
																	marginRight: 10,
																	borderWidth: 5,
																	borderStyle: "solid",
																	borderColor: "var(--dark-blue)"
																}}
																alt={row.original.sequenceName}
															/>

															{row.original.sequenceName}
														</div>
													</Link>
												</td>
											)}
											{cell.column.id === "courseName" && (
												<td {...cell.getCellProps()}>
													<Link
														to={{
															pathname: `${pathname}/insights`,
															search: `${search}&courseId=${row.original.courseId}`
														}}
													>
														<div
															style={{
																padding: "10px",
																display: "flex",
																flexDirection: "row",
																alignContent: "center",
																alignItems: "center"
															}}
														>
															<div className="statsButton">
																<FontAwesomeIcon icon={faChartLine} />
															</div>
															<img
																src={row.original.courseImg}
																width={40}
																height={40}
																style={{ borderRadius: 6, marginRight: 10 }}
																alt={row.original.courseName}
															/>

															{row.original.courseName}
														</div>
													</Link>
												</td>
											)}
											{cell.column.id === "gender" && (
												<td
													{...cell.getCellProps()}
													style={{
														padding: "10px"
													}}
												>
													<Tag
														color={
															row.original.gender === "male"
																? chartColors[0]
																: chartColors[1]
														}
													>
														{cell.render("Cell")}
													</Tag>
												</td>
											)}
											{cell.column.id === "learningPath" && (
												<td
													{...cell.getCellProps()}
													style={{
														padding: "10px"
													}}
												>
													<Tag color={row.original.color}>{cell.render("Cell")}</Tag>
												</td>
											)}
											{cell.column.id === "link" && (
												<td {...cell.getCellProps()}>
													<Link to={cell.value}>
														<Button icon={<LineChartOutlined />} size="small">
															Analyse
														</Button>
													</Link>
												</td>
											)}
											{cell.column.id !== "userGroupName" &&
												cell.column.id !== "userGroupNames" &&
												cell.column.id !== "sequenceName" &&
												cell.column.id !== "courseName" &&
												cell.column.id !== "gender" &&
												cell.column.id !== "learningPath" &&
												cell.column.id !== "link" && (
													<td
														{...cell.getCellProps()}
														style={{
															padding: "10px"
														}}
													>
														{cell.render("Cell")}
													</td>
												)}
										</React.Fragment>
									)
								})}
							</TableRow>
						)
					})}
				</tbody>
			</table>
		</TableContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupColors: selectOrgUserGroupColorsMap
})

export default connect(mapStateToProps)(BasicTable)
